import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';

import './index.css';
import 'react-multi-carousel/lib/styles.css';
import './styles/fonts/policies.css';
import './styles/fonts/responsivity/policies-768.css';
import './styles/fonts/responsivity/policies-1300.css';
import './styles/fonts/responsivity/policies-1600.css';

import getStore from './bundles';
import rootStore from './pages/root';
import * as serviceWorker from './serviceWorker';

const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(rootStore(getStore()));
// ReactDOM.render(root(getStore()), document.getElementById("root"));
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
ReactGA.initialize(process.env.REACT_APP_GTM_MESURE_ID, {
  gtagUrl: 'https://www.googletagmanager.com/gtm.js',
});
